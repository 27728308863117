import { Injectable } from '@angular/core';
import type { NamespaceFile } from '../enums/namespace';
import { exists, hasLoadedNamespace, loadNamespaces, t } from 'i18next';

@Injectable({
    providedIn: 'root',
})
export class TranslateService {
    protected translations = new Map<string, string | undefined>();

    async t(key: string | undefined, ns: NamespaceFile = 'general', options?: Record<string, string | number | undefined | null>): Promise<string> {
        if (key == null) {
            return '';
        }

        if (!hasLoadedNamespace(ns)) {
            await loadNamespaces(ns);
        }

        const untranslated = `${ns}:${key}`;

        if (!this.exists(untranslated)) {
            return key;
        }

        const code = untranslated + JSON.stringify(options);
        const cached = this.translations.get(code);

        if (typeof cached === 'string') {
            return cached;
        }

        const opt = options || {};

        for (const [ key, val ] of Object.entries(opt)) {
            let v = '';
            if (typeof val === 'string' && val.includes('$t')) {
                // If no namespace was specified on the inner translation, we add the namespace of the outer translation.
                v = !val.includes('.') && !val.includes(':') ? val.replace('\$t(', `\$t(${ns}.`) : val;

                // For nested translations, we need to replace the dots with colons, since that's the separator between key and namespace that is configured.
                // Hopefully we can remove this piece of code when we stop using ng-i18next and the i18next.t() function.
                v = v.replace('.', ':');
            }

            opt[key] = v || val;
        }

        const translated = this.translate(untranslated, options || {});

        this.translations.set(code, translated);

        return translated;
    }

    syncT(key?: string | null, ns: NamespaceFile = 'general', options?: Record<string, string | number | undefined | null>): string {
        if (typeof key !== 'string') {
            return '';
        }

        const untranslated = `${ns}:${key}`;

        if (!hasLoadedNamespace(ns)) {
            console.error(`Namespace "${ns}" has not been loaded. 🐲`);
            return untranslated;
        }

        return t(untranslated, options);

    }

    protected translate(key: string, options?: Record<string, string | number | undefined | null>): string {
        return t(key, options);
    }

    protected exists(key: string): boolean {
        return exists(key);
    }
}
